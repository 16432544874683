.container {
  width: 100%;
  margin-top: 90px;
  padding: 10px;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.subText {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
  font-size: 16px;
  width: 90%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.textArea {
  width: 85%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-height: 100px;
  resize: vertical;
  margin-bottom: 15px;
}

.budgetContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.input {
  width: 48%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.inputFullWidth {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.mediaContainer {
  margin-bottom: 15px;
  width: 100%;
}

.mediaLabel {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: bold;
}

.mediaInput {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 90%;
}

.mediaPreviewContainer {
  margin-top: 15px;
  text-align: center;
}

.mediaPreview {
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
  object-fit: cover;
}

.Button-Group{
  flex-direction: row; /* Changed from column to row */
  gap: 10px; /* Space between buttons */
  justify-content: space-between; 
  width: 100%;
  display: flex;
}

.button {
  flex: 1; /* Allow button to grow and shrink */
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.use-agent  {
  flex: 1; /* Allow button to grow and shrink */
  padding: 0.75rem;
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.use-agent.enabled {
  background-color: #007bff; /* Active state background color */
  cursor: pointer; /* Pointer cursor for enabled state */
}

.use-agent.enabled:hover {
  background-color: #0056b3; /* Hover state background color */
}

.buttonDisabled {
  background-color: #aaa;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.unitsComponentContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .budgetContainer {
    flex-direction: column;
  }

  .input {
    width: 100%;
    margin-bottom: 15px;
  }

  .button {
    margin-bottom: 20px;
  }
}
