/* Centering the signup form */
.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height for centering */
  margin: 0 auto;
  width: 90%;
  max-width: 500px; /* Adjusted max-width */
  background-color: #414242;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.signup-form h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #007bff;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}

.icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #007bff;
  font-size: 1.25rem; /* Adjusted icon size */
}

input[type="text"],
input[type="tel"],
input[type="password"] {
  width: 100%;
  padding: 0.75rem 2.5rem; /* Adjusted padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box; /* Ensures padding and border are included in width */
}

input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
  width: auto; /* Ensures checkbox doesn't stretch */
}

.checkbox-group {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: 10px; /* Space between checkboxes */
  padding-left: 1.5rem; /* Matches padding of input fields */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px; /* Reduced margin for closer spacing */
}

.checkbox-group span {
  display: block; /* Ensures text wraps properly */
}

.checkbox-group label {
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;
}

.checkbox-group label a {
  color: #007bff;
  text-decoration: none;
}

.checkbox-group label a:hover {
  text-decoration: underline;
}

.button-group {
  width: 100%;
  display: flex;
  flex-direction: row; /* Changed from column to row */
  gap: 10px; /* Space between buttons */
  justify-content: space-between; /* Ensure buttons are spaced out */
}

.submit-button {
  flex: 1; /* Allow button to grow and shrink */
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.secondary-button {
  flex: 1; /* Allow button to grow and shrink */
  padding: 0.75rem;
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.secondary-button:hover {
  background-color: #007bff;
  color: white;
}

.error-text {
  color: #dc3545;
  font-size: 0.875rem;
  margin: 10px 0;
}

.success-text {
  color: #28a745;
  font-size: 0.875rem;
  margin: 10px 0;
}
