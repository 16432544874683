.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.transfers-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.transfers-table th, 
.transfers-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.approve-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.approve-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.approve-button:hover:not(:disabled) {
  background-color: #218838;
}

.error {
  color: red;
  text-align: center;
}
