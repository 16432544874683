.marketingContainer {
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

.subheading {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.upgradeButton,
.learnMoreButton {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.upgradeButton {
  background-color: #007aff;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.3);
}

.upgradeButton:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 20px rgba(0, 86, 179, 0.4);
}

.learnMoreButton {
  background-color: transparent;
  color: #007aff;
  border: 2px solid #007aff;
}

.learnMoreButton:hover {
  background-color: #007aff;
  color: white;
  border-color: #0056b3;
}
