.persuasion-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    max-width: 900px;
    margin: auto;
    color: #333;
    text-align: center;
  }
  
  .hero-section {
    background-color: #f9fafc;
    padding: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .headline {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1a73e8;
  }
  
  .subheadline {
    font-size: 1.2rem;
    margin-top: 15px;
  }
  
  .price {
    font-size: 1.5rem;
    font-weight: 700;
    color: #f39c12;
  }
  
  .content-section {
    text-align: left;
    margin-top: 30px;
  }
  
  .highlight {
    font-size: 1.8rem;
    color: #16a085;
    margin-top: 20px;
  }
  
  p {
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .testimonial {
    font-style: italic;
    margin: 20px 0;
    border-left: 4px solid #1a73e8;
    padding-left: 15px;
    color: #555;
  }
  
  .urgency {
    font-size: 1.2rem;
    color: #e74c3c;
    font-weight: bold;
  }
  
  .cta-buttons {
    margin-top: 30px;
  }
  
  .cta-button {
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .cta-button.primary {
    background-color: #1a73e8;
    color: #fff;
  }
  
  .cta-button.primary:hover {
    background-color: #1558b6;
  }
  
  .cta-button.secondary {
    background-color: #f39c12;
    color: #fff;
  }
  
  .cta-button.secondary:hover {
    background-color: #d87f0f;
  }
  