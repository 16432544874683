.floating-component {
    position: fixed;
    bottom: 0px; /* Adjust as needed */
    height: 300px; /* Initial height */
    left: 10px;
    transition: height 0.5s ease; /* Smooth transition for height change */
    background-color: white;
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999; /* Ensure it's above other components */
    width: 90%;
    align-items: center;
    margin-left: -10px;
  }
  
  .floating-component.expanded {
    height: 400px; /* Adjust as needed */
  }
  
  .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .input-field{
    width: 90%;
    align-items: center;
    margin-left: -5px;
}  
  .submit-button {
    width: 90%;
    margin-left: -5px;
    background-color: azure;
  }