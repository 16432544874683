/* Dashboard.css */
.dashboard-container {
    background: rgba(240, 240, 240, 0.972); /* Blurred fine tan/brown background color */
    padding: 20px;
    border-bottom: 10px;
    height: 800px;
    backdrop-filter: blur(5px); /* Add a slight blur effect */
  }
  .toggle-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 5px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #007bff;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }