/* Chatbot container */
.chatbot-container {
  position: relative;
  margin-top: 10px;
  font-family: Arial, sans-serif;
  width: auto;
  margin-left: 0px;

}

/* Chat history */
.chat-history {
  overflow-y: auto;
  max-height: calc(100vh - 5px); /* Adjusted max-height to leave space for input and bottom icon */
  padding: 10px;
  margin-bottom: 55px;
}

/* Message styling */
.message {
  margin-bottom: 10px;
}

.centered-message {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
}


.user-message {
  background-color: #dedede;
  color: rgb(2, 1, 1);
  padding: 8px 12px;
  display: inline-block;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: right;
  align-items: right;
}

.bot-message {
  background-color: #EFEFEF;
  color: #333;
  padding: 8px 12px;
  display: inline-block;
}

/* Input form */
.input-form {
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: transparent;
  bottom: 9px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: 10px;
}

.input-field {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%; /* Adjusted width to accommodate the icon */
  margin-top: 10px;
}

.send-button {
  padding: 10px;
  font-size: 16px;
  background: #505050;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 32px; /* Adjusted width to accommodate the icon */
  right: 20px;
  height: 32px;
  bottom: 14px;
  position: fixed;
}

/* Icon at the bottom of the page */
.icon-bottom {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999; /* Ensure the icon is on top of other content */
}

/* Icon styling */
.icon-bottom svg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007AFF;
  padding: 10px;
  cursor: pointer;
}
