.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* No space to the left or right */
  margin: 0;
  width: 100%; /* Full-width layout */
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 90px;
}

/* Profile Section */
.profileSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1.5rem 0; /* Space around profile content */
  width: 100%;
}

.profileIcon {
  font-size: 5rem;
  color: #ccc;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
}

.fullName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.serviceRendered {
  font-size: 1rem;
  color: #777;
}

/* Cover Photo */
.coverPhoto {
  width: 100%;
  height: 150px;
  background-color: #f4f4f4;
  position: relative;
}

.dots-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  margin: 0 4px;
}

/* Stats Section */
.stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1rem 0;
  background-color: white;
}

.statItem {
  text-align: center;
}

.statItem strong {
  font-size: 1.2rem;
  color: #333;
}

.statItem span {
  font-size: 0.9rem;
  color: #777;
}

/* Service Description */
.serviceDescription {
  padding: 1rem;
  text-align: center;
  font-size: 1.1rem;
  color: #444;
  line-height: 1.6;
  background-color: white;
  margin-top: 1rem;
  width: 100%;
}

/* Phone Number */
.phoneNumber {
  padding: 0.5rem;
  font-size: 1.1rem;
  color: #444;
  background-color: white;
  text-align: center;
  margin-top: 1rem;
}

/* Button Container */
.buttonContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
}

.primaryButton,
.secondaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primaryButton {
  background-color: #007aff;
  color: white;
}

.primaryButton:hover {
  background-color: #005bb5;
}

.secondaryButton {
  background-color: #f5f5f5;
  color: #333;
}

.secondaryButton:hover {
  background-color: #ddd;
}

.icon {
  margin-right: 8px;
}

/* Menu Icon */
.menuIcon {
  font-size: 1.5rem;
  color: #777;
  cursor: pointer;
  transition: color 0.3s ease;
}

.menuIcon:hover {
  color: #333;
}

/* Spinner (for loading state) */
.apple-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.apple-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #007aff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
