/* AccountUpgrade.css */
.upgrade-container {
  max-width: 960px;
  margin: 100px auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #333;
}

.upgrade-header {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.back-link {
  font-size: 16px;
  text-decoration: none;
  color: #0070f3;
  position: absolute;
  left: 20px;
  top: 20px;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #005bb5;
}

.upgrade-header h2 {
  font-size: 28px;
  margin: 10px 0;
}

.upgrade-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.upgrade-search input[type="text"] {
  flex: 1;
  min-width: 250px;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.upgrade-search input[type="text"]:focus {
  border-color: #0070f3;
}

.user-count {
  margin-left: 20px;
  font-size: 16px;
  color: #555;
}

.user-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.user-card {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.user-info p {
  margin: 6px 0;
  font-size: 14px;
}

.upgrade-btn {
  margin-top: 15px;
  padding: 10px 0;
  background: #0070f3;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.upgrade-btn:hover {
  background: #005bb5;
}
