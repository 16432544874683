.guidelines-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.guidelines-container h1 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 1.5rem;
}

.guidelines-container h2 {
  font-size: 1.5rem;
  color: #0056b3;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.guidelines-container p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.guidelines-container a {
  color: #007bff;
  text-decoration: none;
}

.guidelines-container a:hover {
  text-decoration: underline;
}

.final-note {
  margin-top: 2rem;
  font-style: italic;
}
