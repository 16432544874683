.servicemen-page {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    max-width: 1000px;
    margin: auto;
  }
  
  .page-title {
    text-align: center;
    font-size: 2.5rem;
    color: #1a73e8;
    margin-bottom: 20px;
  }
  
  .intro-text {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
  }
  
  .extra-content {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9fafc;
    border-radius: 10px;
  }
  
  .extra-content h2 {
    color: #16a085;
  }
  
  .extra-content p {
    line-height: 1.6;
    color: #444;
  }
  