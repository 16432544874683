.job-details-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.job-budget {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
}

.job-call-count {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
}

.job-call-count .icon {
    font-size: 18px;
    color: #007bff; /* Blue color for the phone icon */
    margin-right: 5px;
}

.job-budget p, 
.job-call-count p {
    margin: 0;
}

.job-details-footer:hover {
    background-color: #f1f1f1; /* Slight hover effect for better UX */
}
