/* Ensure the container covers the full height */
.navigation-container {
  position: relative;
  margin-top: -1px;
  width: 90%; /* Corrected from 'the 90%' */
  z-index: 1001; /* Ensures the menu is on top of everything */
}

.menu-items {
  position: fixed; /* Fix it to the side */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full height of the screen */
  background-color: #edefef; /* Use a background that contrasts with the page */
  transform: translateX(-100%); /* Start off-screen */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  margin: auto;
}

.menu-items.visible {
  transform: translateX(0); /* Slide in when visible */
}

.menu-icon {
  font-size: 24px;
  padding: -10px 20px;
  cursor: pointer;
  z-index: 1000; /* Lower than the navigation container */
  position: relative;
}

.menu-list {
  list-style-type: none;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.menu-list li {
  margin-left: 10px;
  text-decoration: none !important; /* No underline */
  color: #070707; /* Color set */
}

.section-separator {
  border-top: 1px solid #f3f5f6;
  margin: 20px 0;
}

.user-info {
  padding: 20px;
  font-size: 16px;
}

.sign-out {
  color: red;
}

.Block {
 border-color: #070707;  
}
@media (max-width: 768px) {
  .menu-items {
    width: 80%; /* Slightly narrower menu for smaller screens */
  }

  .menu-list {
    font-size: 16px;
  }
}

.menu-list sign-out {

  margin-top: 10px;
}
