/* General Reset */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* Styling for the Marketing Container */
.marketing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background: linear-gradient(120deg, #74ebd5, #9face6);
  color: #2c3e50;
  text-align: center;
  padding: 20px;
}

/* Marketing Content */
.marketing-content {
  max-width: 600px;
  background: white;
  
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Fade-in animation */
  transform: scale(0.8); /* Initial scale for animation */
  animation: fadeIn 1.5s ease-out forwards, scaleUp 1.5s ease-out forwards;
}

/* Headline Text with Animation */
.headline {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #34495e;
}

/* Subheadline Text */
.subheadline {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #7f8c8d;
}

/* Highlight Text */
.highlight {
  font-size: 1.1rem;
  color: #16a085;
  margin-bottom: 30px;
  font-weight: bold;
}


.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

button {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.learn-more-btn {
  background-color: transparent;
  color: #797a7c; /* Text color matching the border */
  border: 2px solid #797a7c; /* Blue border */
  transition: all 0.3s ease; /* Smooth transition for effects */

}

.learn-more-btn:hover {
  background: linear-gradient(135deg, #9face6, #74ebd5);
  box-shadow: 0 6px 20px rgba(116, 235, 213, 0.4);
  transform: translateY(-3px);
}

.get-started-btn {
  background: linear-gradient(135deg, #f26c6c, #f79d65);
  color: #ffffff;
}

.get-started-btn:hover {
  background: linear-gradient(135deg, #f79d65, #f26c6c);
  box-shadow: 0 6px 20px rgba(242, 108, 108, 0.4);
  transform: translateY(-3px);
}

/* Animations */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
