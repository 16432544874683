
.home-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.overlay-text {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.2;
}

.overlay-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.styled-button {
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.styled-button.primary {
  background: linear-gradient(90deg, #ff6f61, #de8f7d);
}

.styled-button.primary:hover {
  background: linear-gradient(90deg, #ff6f61, #ff7f5d);
  transform: scale(1.05);
}

.styled-button.secondary {
  background: #fff;
  color: #333;
}

.styled-button.secondary:hover {
  background: #f0f0f0;
  transform: scale(1.05);
}

.legacy-text {
  font-size: 0.9rem;
  margin-top: 20px;
  color: #ccc;
    }
