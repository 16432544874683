/* Common styles for job containers */
.job-container {
  background-color: rgb(226, 225, 225);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for job creator name */
.job-creator {
  margin-bottom: 5px;
}

/* Styles for job description */
.job-description {
  margin-bottom: 15px;
}

/* Styles for job details container */
.job-details-container {
  color: #fff;
  margin-top: 20px;
  background-color: #333;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 -10px;
  border-radius: 8px;
}

/* Styles for job details */
.job-detail {
  font-size: 16px;
  vertical-align: middle;
}

/* Styles for loading indicator */
.loader {
  margin-bottom: 20px;
  text-align: center;
  font-size: 18px;
  color: #007bff;
}

/* Media query for iPad */
@media screen and (min-width: 768px) {
  /* Adjust margin for the main container */
  .main-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
  }
  
  /* Adjust width for job containers */
  .job-container {
    width: calc(50% - 20px); /* Two columns with a 20px gap between them */
  }
}

/* Media query for larger screens */
@media screen and (min-width: 1024px) {
  /* Adjust width for job containers */
  .job-container {
    width: calc(33.33% - 20px); /* Three columns with a 20px gap between them */
  }
}

.container {
  padding: 20px;
}

.jobSection,
.newJobSection {
  margin-bottom: 40px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader p {
  margin-top: 15px;
  color: #555;
  font-size: 1rem;
}
