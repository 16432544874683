.job-openings-container {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
}

.loading {
  font-size: 1.2rem;
  text-align: center;
  color: #4d4d4d;
  margin-top: 50px;
}

.no-jobs {
  font-size: 1.2rem;
  text-align: center;
  color: #888;
  margin-top: 50px;
}

.job-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.job-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.job-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.job-details {
  margin-top: 15px;
  text-align: center;
}

.job-description {
  font-weight: bold;
  font-size: 1.1rem;
  color: #4d4d4d;
}

.job-location {
  color: #888;
  font-style: italic;
  margin-top: 5px;
}

.job-budget,
.job-date {
  margin-top: 10px;
  color: #555;
}

.job-completed {
  margin-top: 15px;
  color: red;
  font-weight: bold;
}

.job-available {
  margin-top: 15px;
  color: green;
  font-weight: bold;
}
