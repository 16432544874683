/* AppleForm.css */

.apple-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #545151;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.k2 {
  text-align: center;
  font-weight: 600;
  color: #d7d3d3;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

/* Input container to hold the icon and input field */
.input-container {
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 10px; /* Space from the left edge */
  color: #888;
  font-size: 1.2rem;
}

/* Input field styling with padding for icon */
input {
  padding-left: 30px; /* Adjusted padding for icon space */
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  font-family: inherit;
  color: #333;
  outline: none;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #007aff;
}

/* Button styling */
.apple-blue-button {
  display: inline-block;
  width: 100%;
  padding: 12px;
  background-color: #02366d;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: inherit;
  margin-left: -1px;
}

.apple-blue-button:hover {
  background-color: #005bb5;
}

.apple-blue-button.disabled {
  background-color: #99c1ff;
  cursor: not-allowed;
}

/* Error and response messages */
.form-error,
.backend-response {
  text-align: center;
  color: #d9534f; /* Red for errors */
  margin-bottom: 20px;
  font-size: 14px;
}

/* Form links styling */
.form-links {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.form-links button {
  background: none;
  border: none;
  color: #007aff;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  font-weight: 600;
}

.form-links button:hover {
  text-decoration: underline;
}

/* Reset form styling */
.reset-form {
  margin-top: 20px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reset-form h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.reset-form input {
  padding-left: 15px;
  width: calc(100% - 30px);
  margin-bottom: 10px;
}

.reset-form button {
  width: 100%;
  margin-bottom: 10px;
}

/* Cancel button in the reset form */
.reset-form .forgot-password {
  color: #333;
  text-decoration: underline;
  text-align: center;
}

.reset-form .forgot-password:hover {
  color: #007aff;
}

/* Adjust other general styles as needed for consistency */
