.user-jobs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 90px;
    width: 90%;
    margin-left: 10px;
    margin-bottom: 100px;
  }
  
  .job-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
  }
  
  .job-card h3 {
    margin-top: 0;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .button-container button:hover {
    background-color: #24568c;
  }
  
.confirm-ad-setup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f9f9f9;
  font-family: 'Arial', sans-serif;
}

.processing-modal {
  text-align: center;
  background: white;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  width: 300px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
  animation: spin 1s linear infinite;
}

.processing-modal h2 {
  font-size: 18px;
  color: #333;
  margin-top: 0;
  line-height: 1.5;
}

/* Loader Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Fade-in Animation for Modal */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
