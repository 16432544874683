.container {
    font-family: Arial, sans-serif;
    color: #0e0d0d;
    width: 90%;
  }
  
  .icon-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 89%;
  }
  
  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 33%;
    text-align: center;
  }
  
  .icon-text {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
  }
  
  .transfer-section {
    margin-top: 20px;
    padding: 15px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
  }
  
  .input {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .recipient-name {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .submit-button {
    width: 100%;
    padding: 14px;
    background-color: #007aff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .loader {
    width: 25px;
    height: 25px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007aff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 10px auto;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  