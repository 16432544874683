/* AccountOverview.css */
.account-overview-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.account-section {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.account-number {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
}

.balance-section {
  display: flex;
  align-items: center;
}

.balance {
  font-size: 1.8rem;
  font-weight: 700;
  color: #007aff;
}

.loading {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin-top: 20px;
}