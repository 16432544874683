.units-component {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f5f5f5;
    border: 2px solid #c0c0c0;
    border-radius: 10px;
    padding: 20px;
    width: 70%;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button {
    background-color: #ff6767; /* Apple red color */
    color: white;
  }
  
  .close-button:hover {
    background-color: #e60000; /* Darker red on hover */
  }
  
  .top-up-button {
    background-color: #4cd964; /* Apple green color */
    color: white;
  }
  
  .top-up-button:hover {
    background-color: #3dbb3d; /* Darker green on hover */
  }
  