/* Base styling */
.user-profile {
    max-width: 600px;
    margin: auto;
    padding: 2rem;
    border-radius: 12px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

/* Header */
.user-profile h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
}

/* Profile details */
.profile-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detail {
    font-size: 1rem;
    color: #555;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background: #fff;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.detail:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background: #f3f3f3;
}

/* Label styling */
.detail span {
    font-weight: 600;
    color: #333;
}

/* Loading and error styles */
.loading,
.error {
    font-size: 1rem;
    color: #888;
}
.error {
    color: #e74c3c;
}
