/* AgentPromo.css */
.agent-promo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f8fafc;
    padding: 4rem 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 2rem auto;
  }
  
  .agent-promo-content {
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .promo-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #007bff;
  }
  
  .promo-message {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .promo-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .learn-more-btn,
  .get-started-btn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-btn {
    background-color: #007bff;
    color: #fff;
  }
  
  .learn-more-btn:hover {
    background-color: #0056b3;
  }
  
  .get-started-btn {
    background-color: #28a745;
    color: #fff;
  }
  
  .get-started-btn:hover {
    background-color: #1e7e34;
  }
  /* AgentPromo.css */
.agent-promo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8fafc;
  padding: 4rem 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 2rem auto;
}

.agent-promo-content {
  color: #333;
  font-family: 'Arial', sans-serif;
}

.promo-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #007bff;
}

.promo-message {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
}

.promo-benefits {
  text-align: left;
  margin: 1rem auto;
  padding-left: 1.5rem;
  color: #555;
}

.promo-benefits li {
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.promo-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.learn-more-btn,
.get-started-btn {
  font-size: 1.2rem;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more-btn {
  background-color: #007bff;
  color: #fff;
}

.learn-more-btn:hover {
  background-color: #0056b3;
}

.get-started-btn {
  background-color: #28a745;
  color: #fff;
}

.get-started-btn:hover {
  background-color: #1e7e34;
}

.interested-users-form {
  margin-top: 2rem;
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-intro {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #555;
  font-style: italic;
  text-align: center;
}
.form-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.submit-btn {
  padding: 12px 20px;
  font-size: 1rem;
  background-color: #0078D7;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #005a9e;
}