.container {
    margin: 20px;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .back-link {
    text-decoration: none;
    color: blue;
  }
  
  .content {
    margin-bottom: 20px;
  }
  
  .error {
    color: red;
  }
  
  .accounting-info {
    background-color: rgb(226, 225, 225);
    border-radius: 8px;
    padding: 10px;
    width: 90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto 20px auto;
  }
  
  .footer {
    display: flex;
    justify-content: center;
  }
  
  .view-jobs-btn {
    margin-right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    color: black;
    width: 150px;
  }
  