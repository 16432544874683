/* CommentsComponent.css */

.comments-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #333;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .comments-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .comments-list {
    list-style: none;
    padding: 0;
  }
  
  .comment-item {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .comment-input-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
  }
  
  .comment-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    width: 78%;
  }
  
  .send {
    width: 20%; /* Adjust width as needed */
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  

  /* CSS for loading spinner */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3f51b5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

  
  .send-button:hover {
    background-color: #0056b3;
  }
  
  .send-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3); /* Apple-style focus effect */
  }
  
  .send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .send-icon {
    vertical-align: middle;
    

  }
  