.job-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  background-color: #f9f9f9; /* Neutral light background */
  color: #333;
  padding: 10px;
  min-height: 100vh; /* Full viewport height */
}

.job-details-card {
  background: #fff; /* Pure white card */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  max-width: 600px; /* Restrict width for focus */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.job-details-card:hover {
  transform: translateY(-4px); /* Subtle hover effect */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15); /* Enhance shadow */
}

/* Header */
.job-creator {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #222; /* Slightly darker than general text */
}

.job-location {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555; /* Muted gray */
}

.job-location .icon {
  margin-right: 8px;
  color: #007BFF; /* Accent color */
}

/* Date */
.job-date {
  font-size: 12px;
  color: #999; /* Light gray text for date */
  margin-top: 4px;
  margin-bottom: 16px;
}

/* Description */
.job-description {
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  line-height: 1.6;
}

/* Footer */
.job-details-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  border-top: 1px solid #eee;
  padding-top: 16px;
}

.job-budget {
  font-weight: 600;
  font-size: 16px;
  color: #222; /* Emphasized text */
}

.job-call-count {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.job-call-count .icon {
  margin-right: 4px;
}

/* Actions Section */
.job-actions {
  margin-top: 20px; /* Spacing between job details and actions */
  display: flex;
  flex-direction: column; /* Stack actions vertically */
  gap: 16px; /* Ensure consistent spacing between actions */
}

.action-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comments-button,
.call-button,
.phone-number-hide-button {
  background-color: #007BFF; /* Accent color */
  color: #fff; /* White text */
  border: none;
  border-radius: 6px; /* Rounded corners */
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.comments-button:hover,
.call-button:hover,
.phone-number-hide-button:hover {
  background-color: #0056b3; /* Darker accent */
  transform: scale(1.05); /* Slight zoom effect */
}

.phone-number-display {
  background-color: #f1f1f1; /* Subtle gray background */
  padding: 10px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phone-number-text {
  font-size: 14px;
  color: #007BFF;
  cursor: pointer;
}

.phone-number-text:hover {
  text-decoration: underline;
}

.phone-number-popup {
  background-color: #f1f1f1; /* Subtle gray background */
  padding: 10px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upgrade-message {
  margin-top: 16px;
  font-size: 14px;
  color: #ff4d4d; /* Red for urgency */
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .job-details-card {
    padding: 16px;
  }

  .comments-button,
  .call-button,
  .phone-number-hide-button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .job-actions {
    flex-direction: column; /* Stack actions vertically on smaller screens */
  }

  .phone-number-display {
    flex-direction: column; /* Stack phone number and button */
    gap: 8px;
  }
  }
