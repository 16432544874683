/* JobSkeleton.css */

.skeletonCard {
    background: linear-gradient(135deg, #f5f5f7, #e0e0e2);
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    margin: 20px auto; /* Added margin for spacing between skeletons */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
    padding: 20px;
    margin-right: 10px;
  }
  
  .skeletonHeader,
  .skeletonBody,
  .skeletonFooter {
    background: linear-gradient(90deg, #e0e0e2 25%, #f5f5f7 50%, #e0e0e2 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 8px;
  }
  
  .skeletonHeader {
    height: 20px;
    width: 60%;
    margin-bottom: 16px;
  }
  
  .skeletonBody {
    height: 16px;
    width: 80%;
    margin-bottom: 12px;
  }
  
  .skeletonFooter {
    height: 16px;
    width: 40%;
    margin-top: 16px;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
