/* Container Styling */
.servicemen-list-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif; /* Sleek modern font */
  box-sizing: border-box;
  background: #fefefe; /* Clean white background */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Filters Section */
.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #f3f4f6; /* Light modern gray */
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.search-bar {
  flex: 1 1 300px;
  max-width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  background: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s, background-color 0.3s;
}

.search-bar:focus {
  outline: none;
  background: #eef2f7;
  box-shadow: 0 0 8px rgba(0, 115, 255, 0.2);
}

.filter-dropdown {
  flex: 1 1 200px;
  max-width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  background: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.filter-dropdown:hover, .filter-dropdown:focus {
  background: #eef2f7;
  box-shadow: 0 0 8px rgba(0, 115, 255, 0.2);
}

/* Loading and Error States */
.loading, .error {
  text-align: center;
  font-size: 1.2em;
  color: #333;
}

.no-servicemen {
  text-align: center;
  font-size: 1.2em;
  color: #666;
}

/* Servicemen List */
.servicemen {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Individual Cards */
.serviceman-card {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  padding: 20px;
  background: linear-gradient(to bottom right, #ffffff, #f9fafb);
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.serviceman-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.service-circle {
  display: inline-block;
  padding: 15px;
  border-radius: 50%;
  background: linear-gradient(135deg, #0073ff, #009eff);
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.serviceman-name-preview {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
  text-transform: capitalize;
}

.serviceman-description {
  color: #555;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.serviceman-location {
  color: #0073ff;
  font-weight: bold;
  text-transform: capitalize;
}

.serviceman-rating {
  color: #ffcc00;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .serviceman-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .filters-container {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    padding: 15px;
  }

  .search-bar, .filter-dropdown {
    flex: 1 1 100%;
  }

  .serviceman-card {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

@media (max-width: 480px) {
  .service-circle {
    font-size: 1rem;
    padding: 10px;
  }

  .serviceman-name-preview {
    font-size: 1.2rem;
  }

  .serviceman-description {
    font-size: 0.9rem;
  }

  .card-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  }
